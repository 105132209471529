<template lang="pug">
#start-kit-checklist.step-component
  h2 Your kit checks out!
  p Before we get started, a couple tips for you to get ready.
  .form-field.checkbox(
    v-for="(item, key) in items",
    :key="key",
    v-if="(key != 'understandRecording' && proceededWithoutVideo) || !proceededWithoutVideo"
  )
    .input-container
      input(
        type="checkbox",
        v-model="itemStatus[key]",
        :value="itemStatus[key]",
        :id="key",
        :name="key"
      )
      label.label(:for="key") {{ item }}

  button.button(@click="clickHandler()", :disabled="!checklistComplete") I'm ready
</template>

<script>
// ***** Sample step config *****
// {
//   component: 'StartKitChecklist',
//   config: {
//     items: {
//       minutesFree: 'I have 35 minutes free now to complete the Kit',
//       drankWater: 'I drank 2 glasses of water',
//       understandRecording: 'I understand that my actions will be video-recorded',
//     },
//   },
// },
import { mapGetters } from 'vuex'
import Holidays from 'date-holidays'
import AmplitudeAPI from '@/utils/amplitude'
import MixpanelAPI from '@/utils/mixpanel'

export default {
  props: {
    items: { type: Object, required: true },
  },

  data() {
    return {
      itemStatus: Object.keys(this.items).reduce(
        (obj, key) => Object.assign(obj, { [key]: false }),
        {}
      ),
    }
  },

  computed: {
    ...mapGetters({
      proceededWithoutVideo: 'user/getVideoPermission',
    }),
    checklistComplete,
  },

  watch: {},

  methods: {
    clickHandler,
    gotoNext,
    checkBusinessHours,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function checklistComplete() {
  if (this.proceededWithoutVideo) {
    this.itemStatus.understandRecording = true
  }
  return Object.values(this.itemStatus).indexOf(false) == -1
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function clickHandler() {
  if (localStorage.getItem('displayWarning') == 'true') {
    this.checkBusinessHours()
  } else {
    this.gotoNext()
  }
}
function checkBusinessHours() {
  // Function to check if the current day is a holiday
  function checkHolidays(date) {
    return (
      !(holidayCheck && holidays.some(holiday => holiday.name == holidayCheck[0].name)) &&
      date.getDay() != 0
    )
  }
  function checkIfFriSat(date) {
    return [5, 6].includes(date.getDay())
  }
  function checkIfIndependenceDay(date) {
    return date.getMonth() === 6 && [3, 4].includes(date.getDate())
  }
  // Function to check if the day is Friday/Saturday after 12PM
  function checkFriSatAfterNoon(date) {
    return checkIfFriSat(date) && date.getHours() >= 13
  }
  // Function to check if the day is Friday/Saturday before 12PM
  function checkFriSatBeforeNoon(date) {
    return checkIfFriSat(date) && date.getHours() < 13
  }
  const hd = new Holidays('US')
  var holidays = hd.getHolidays(new Date())
  const todaysDate = new Date()
  var holidayCheck = hd.isHoliday(todaysDate)
  var upsDay

  // Filtering out non observed ups holidaus
  holidays = holidays.filter(holiday => {
    return (
      holiday.type == 'public' &&
      ![
        'Martin Luther King Jr. Day',
        "Washington's Birthday",
        'Columbus Day',
        'Veterans Day',
      ].includes(holiday.name)
    )
  })
  // Find the next available UPS day that is not a holiday
  if (checkHolidays(todaysDate) && !checkIfFriSat(todaysDate)) {
    upsDay = new Date(todaysDate)
    upsDay.setHours(6, 0, 0, 0)
  } else {
    var nextDate = new Date(todaysDate)
    nextDate.setDate(nextDate.getDate() + 1)
    holidayCheck = hd.isHoliday(nextDate)
    nextDate.setHours(0)
    while (true) {
      if (checkHolidays(nextDate)) {
        upsDay = new Date(nextDate.setHours(8, 0, 0, 0))
        break
      }
      nextDate.setDate(nextDate.getDate() + 1)
      holidayCheck = hd.isHoliday(nextDate)
    }
  }
  var numberOfHours = (upsDay - todaysDate) / 36e5

  // If Friday, set UPS day to Saturday
  if (todaysDate.getDay() == 5 && checkFriSatBeforeNoon(todaysDate)) {
    upsDay.setDate(upsDay.getDate() + 1)
  }
  //If July 3, set UPS day to july 5
  if (todaysDate.getMonth() == 6 && todaysDate.getDate() == 3) {
    upsDay.setDate(upsDay.getDate() + 2)
  }
  if (numberOfHours > 24 || checkIfFriSat(todaysDate) || checkIfIndependenceDay(todaysDate)) {
    this.$modal.open('HolidayWarning', {
      allowToProceed: true,
      numberOfHours: numberOfHours,
      isSaturday: todaysDate.getDay() == 6,
      isFriday: todaysDate.getDay() == 5,
      isHoliday:
        (numberOfHours > 24 ||
          checkFriSatAfterNoon(todaysDate) ||
          checkIfIndependenceDay(todaysDate)) &&
        !checkFriSatBeforeNoon(todaysDate),
      upsDay: upsDay.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      }),
      gotoNext: this.gotoNext,
    })

    const eventTitle = checkFriSatBeforeNoon(todaysDate) ? 'FriSatMorningAccess' : 'HolidayAccess'
    const eventProperties = {
      source: this.$route.query.src,
    }

    AmplitudeAPI.logEvent(eventTitle, eventProperties)

    MixpanelAPI.track(eventTitle, eventProperties)
  } else {
    this.gotoNext()
  }
}
function gotoNext() {
  this.$emit('complete')
}
</script>
